const routes = [
  {
    path: "/login",
    component: () => import("@/pages/Login.vue"),
  },
  {
    path: "/pengguna",
    component: () => import("@/pages/users/List.vue"),
  },
  {
    path: "/pengguna/create",
    component: () => import("@/pages/users/Form.vue"),
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("@/pages/users/Edit.vue"),
  },

  {
    path: "/profil",
    component: () => import("@/pages/profil/Profil.vue"),
  },
  {
    path: "/profil/:page",
    component: () => import("@/pages/profil/Profil.vue"),
  },
];

export default routes;
