import Vue from "vue";
import VueRouter from "vue-router";
import { jwtDecode } from "jwt-decode";

Vue.use(VueRouter);

const routeModules = require.context("./groups", true, /routes\.js$/);
const routes = [];

routeModules.keys().forEach((fileName) => {
  const routeModule = routeModules(fileName).default;
  routes.push(...routeModule);
});

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // Ambil token dari query parameter
  const token = to.query.token;
  const username = to.query.name;
  const sipData = localStorage.getItem("sipData");
  let userLevel = null;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const now = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && decodedToken.exp > now) {
        const sipData = {
          nama: username,
          token: token,
        };
        localStorage.setItem("sipData", JSON.stringify(sipData));

        userLevel = decodedToken.role;

        const lastRoute = localStorage.getItem("lastRoute") || "/dashboard";
        localStorage.removeItem("lastRoute");
        return next(lastRoute);
      }
    } catch (err) {
      console.error("Invalid token from query:", err);
    }
  }

  if (sipData) {
    try {
      const userAccess = JSON.parse(sipData);
      const decodedToken = jwtDecode(userAccess.token);
      const now = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > now) {
        userLevel = decodedToken.role;
      } else {
        localStorage.removeItem("sipData");
        return next("/login");
      }
    } catch (err) {
      console.error("Invalid token:", err);
      localStorage.removeItem("sipData");
      return next("/login");
    }
  }

  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !sipData) {
    localStorage.setItem("lastRoute", to.fullPath);
    next("/login");
  } else if (to.path === "/login" && sipData) {
    const lastRoute = localStorage.getItem("lastRoute") || "/dashboard";
    localStorage.removeItem("lastRoute");
    next(lastRoute);
  } else if (
    requiredLevel &&
    ![].concat(requiredLevel).some((role) => userLevel.includes(role))
  ) {
    next("/forbidden");
  } else {
    next();
  }
});

export default router;
