<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-th-large f-s-20", title: "Dashboard" },
  {
    path: "/soal",
    icon: "fa fa-print f-s-20",
    title: "Laporan SIP",
    children: [
      {
        path: "/laporan-tahunan",
        title: "Laporan Tahunan",
      },
      {
        path: "/laporan-bulanan",
        title: "Laporan Bulanan",
      },
      {
        path: "/laporan-mingguan",
        title: "Laporan Mingguan",
      },
    ],
  },
  {
    path: "/data-kunjungan",
    icon: "fa fa-users f-s-20",
    title: "Data Kunjungan",
  },
  // {
  //   path: "/pengguna",
  //   icon: "fa fa-users f-s-20",
  //   title: "Pengguna",
  // },
];

export default sidebarMenu;
</script>
