const routes = [
  {
    path: "/laporan-tahunan",
    component: () => import("@/pages/dashboard/Tahunan.vue"),
  },
  {
    path: "/laporan-tahunan/formulir-01",
    component: () => import("@/pages/laporan/form-01/Index.vue"),
  },
  {
    path: "/laporan-tahunan/formulir-20",
    component: () => import("@/pages/laporan/form-19/Index.vue"),
  },
];

export default routes;
