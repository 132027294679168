<template>
  <div>
    <button
      class="pull-right btn btn-icon btn-circle btn-light"
      @click="fetchData()"
    >
      <i v-if="this.loading" class="fa fa-sync fa-spin"></i>
      <i v-else class="fa fa-sync"></i>
    </button>
    <h1 class="page-header">Dashboard <small></small></h1>

    <div class="row"></div>

    <div class="row">
      <div class="col-md-12">
        <div class="panel p-3" ref="barRuang"></div>
      </div>
      <div class="col-md-12">
        <div class="panel p-3" ref="barKesakitanTerbanyak"></div>
      </div>
      <div class="col-md-12">
        <div class="panel p-3" ref="barKesakitanUmum"></div>
      </div>
      <div class="col-md-12">
        <div class="panel p-3" ref="barKesakitanGigi"></div>
      </div>
      <div class="col-md-12">
        <div class="panel p-3" ref="barKesakitanMenular"></div>
      </div>
      <div class="col-md-12">
        <div class="panel p-3" ref="barKesakitanTidakMenular"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../plugins/sipData";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportingExcel from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import seriesLabel from "highcharts/modules/series-label";

// Inisialisasi modul Highcharts
drilldown(Highcharts);
exporting(Highcharts);
exportingExcel(Highcharts);
accessibility(Highcharts);
seriesLabel(Highcharts);

export default {
  mixins: [sipData],
  name: "DashboardPage",
  data() {
    return {
      jumlah_pasien: 0,
      kunjungan_hari: 0,
      kunjungan_bulan: 0,
      kunjungan_tahun: 0,
      jumlah_pustu: 0,
      jumlah_posyandu: 0,
      kunjungan_ruang: [],
      kesakitan_terbanyak: [],
      kesakitan_umum: [],
      kesakitan_gigi: [],
      kesakitan_menular: [],
      kesakitan_tidak_menular: [],
      jenis_kelamin: "",
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get("/v1/dashboard/sip", {
          headers: {
            "X-Token": this.userToken,
          },
        })
        .then((response) => {
          this.kunjungan_ruang = response.data.kunjungan_ruang;
          this.kesakitan_terbanyak = response.data.kesakitan_terbanyak;
          this.kesakitan_gigi = response.data.kesakitan_gigi;
          this.kesakitan_umum = response.data.kesakitan_umum;
          this.kesakitan_menular = response.data.kesakitan_menular;
          this.kesakitan_tidak_menular = response.data.kesakitan_tidak_menular;
          if (this.kunjungan_ruang.length > 0) {
            this.renderBarRuang();
          }
          if (this.kesakitan_terbanyak.length > 0) {
            this.renderKesakitanTerbanyak();
          }
          if (this.kesakitan_gigi.length > 0) {
            this.renderKesakitanGigi();
          }
          if (this.kesakitan_umum.length > 0) {
            this.renderKesakitanUmum();
          }
          if (this.kesakitan_menular.length > 0) {
            this.renderKesakitanMenular();
          }
          if (this.kesakitan_tidak_menular.length > 0) {
            this.renderKesakitanTidakMenular();
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    renderKesakitanTidakMenular() {
      Highcharts.chart(this.$refs.barKesakitanTidakMenular, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "10 Kesakitan Tidak Menular",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Kesakitan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Jumlah Kesakitan : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.kesakitan_tidak_menular.map((data) => ({
              name: `${data.kode_diagnosis_primer} : ${data.diagnosis_primer}`,
              y: parseInt(data.jumlah_kunjungan),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderKesakitanMenular() {
      Highcharts.chart(this.$refs.barKesakitanMenular, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "10 Kesakitan Menular",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Kesakitan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Jumlah Kesakitan : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.kesakitan_menular.map((data) => ({
              name: `${data.kode_diagnosis_primer} : ${data.diagnosis_primer}`,
              y: parseInt(data.jumlah_kunjungan),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderKesakitanUmum() {
      Highcharts.chart(this.$refs.barKesakitanUmum, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "10 Kesakitan Umum",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Kesakitan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Jumlah Kesakitan : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.kesakitan_umum.map((data) => ({
              name: `${data.kode_diagnosis_primer} : ${data.diagnosis_primer}`,
              y: parseInt(data.jumlah_kunjungan),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderKesakitanGigi() {
      Highcharts.chart(this.$refs.barKesakitanGigi, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "10 Kesakitan Gigi dan Mulut",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Kesakitan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Jumlah Kesakitan : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.kesakitan_gigi.map((data) => ({
              name: `${data.kode_diagnosis_primer} : ${data.diagnosis_primer}`,
              y: parseInt(data.jumlah_kunjungan),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderKesakitanTerbanyak() {
      Highcharts.chart(this.$refs.barKesakitanTerbanyak, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "10 Kesakitan Terbanyak",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Kesakitan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Jumlah Kesakitan : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.kesakitan_terbanyak.map((data) => ({
              name: `${data.kode_diagnosis_primer} : ${data.diagnosis_primer}`,
              y: parseInt(data.jumlah_kunjungan),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarRuang() {
      Highcharts.chart(this.$refs.barRuang, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Grafik Kunjungan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Kunjungan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Jumlah Kunjungan : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.kunjungan_ruang.map((data) => ({
              name: data.nama_pemeriksaan,
              y: parseInt(data.j_kunjungan),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    persentse(paramA, paramB) {
      const a = parseInt(paramA);
      const b = parseInt(paramB);
      const c = (a / b) * 100;
      return c.toFixed(2);
    },
    numberFormat(value) {
      if (!value) return "0";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>