const routes = [
  {
    path: "/laporan-bulanan",
    component: () => import("@/pages/dashboard/Bulanan.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-03",
    component: () => import("@/pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-04",
    component: () => import("@/pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-05",
    component: () => import("@/pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-06",
    component: () => import("@/pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-07",
    component: () => import("@/pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-08",
    component: () => import("@/pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-09",
    component: () => import("@/pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-10",
    component: () => import("@/pages/laporan/form-19/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-11",
    component: () => import("@/pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-12",
    component: () => import("@/pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-13",
    component: () => import("@/pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-14",
    component: () => import("@/pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-15",
    component: () => import("@/pages/laporan/form-15/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-16",
    component: () => import("@/pages/laporan/form-16/Index.vue"),
  },
];

export default routes;
