import Dashboard from "@/pages/Dashboard.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "*",
    redirect: "/dashboard/",
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/register",
    component: () => import("@/pages/register/Index.vue"),
  },
  {
    path: "/register/create",
    component: () => import("@/pages/register/Form.vue"),
  },
  {
    path: "/register/update/:id",
    component: () => import("@/pages/register/Form.vue"),
  },
  {
    path: "/register/detail/:id",
    component: () => import("@/pages/register/Detail.vue"),
  },
];

export default routes;
